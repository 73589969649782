<template>
  <div class="register">
    <header>
      <div class="left">
        <img v-lazy="logoImg" class="logo-img" />
        <span>FILCOIN FUND ALLIANCE</span>
      </div>
      <img class="lang-img" src="../../assets/images/cn.png" />
    </header>
    <p class="title">
      Filcoin
      <br />
      基金联盟
    </p>
    <form @submit.prevent="onShowVerify">
      <input
        class="input-invite"
        v-model.trim="referCode"
        type="text"
        placeholder="请输入邀请码"
      />
      <br />
      <button class="confirm-btn">确认</button>
    </form>
    <verify-code
      :visiable="visiable"
      @close="handleClose"
      @click="handleSubmit"
      @refersh="getVerifyCodeImg"
      v-model.trim="verifyCode"
      :verifyImg="verifyCodeImg"
    />
  </div>
</template>

<script>
import VerifyCode from "../../components/VerifyCode.vue";
import { logoImg } from "../../config/constants";
import { getVerifyCode, register } from "../../server/index";
import _ from "lodash";
export default {
  components: { VerifyCode },
  name: "Register",
  data() {
    return {
      logoImg: logoImg,
      verifyCode: "",
      visiable: false,
      verifyCodeImg: "",
      referCode: "",
    };
  },

  methods: {
    async handleSubmit() {
      if (!this.verifyCode) return this.$toast("请输入验证码");
      const registerResult = await register(
        this.getAddress,
        this.verifyCode,
        this.referCode
      );
      if (!registerResult) {
        this.verifyCode = "";
        this.referCode = "";
        this.visiable = false;
        this.verifyCodeImg = await getVerifyCode(this.getAddress, 1);
        return;
      }
      this.visiable = false;
      this.$router.push("/home");
    },
    handleClose() {
      this.visiable = false;
    },
    getVerifyCodeImg: _.throttle(async function () {
      this.verifyCodeImg = await getVerifyCode(this.getAddress, 1);
    }, 2000),
    onShowVerify: _.throttle(function () {
      if (!this.referCode) return this.$toast("请输入邀请码");
      this.visiable = true;
    }, 2000),
  },
  async created() {
    this.referCode = this.$route.query.code;
    await this.getVerifyCodeImg();
  },
};
</script>

<style lang="less" scoped>
.register {
  width: 100%;
  height: 100%;
  background: #333333;
  opacity: 1;
  background-image: url("../../assets/images/register-bg.png");
  background-size: cover;
  box-sizing: border-box;
  padding-top: 15px;
  position: relative;
  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    .left {
      display: flex;
      align-items: center;
      .logo-img {
        width: 28px;
        height: 28px;
        margin-right: 5px;
      }
      span {
        font-size: 16px;
        font-family: "PingFang SC";
        font-weight: bold;
        color: #019969;
      }
    }
    .lang-img {
      width: 22px;
      height: 18px;
    }
  }
  .title {
    position: absolute;
    top: 168px;
    left: 50%;
    transform: translatex(-50%);
    width: 156px;
    height: 87px;
    font-size: 38px;
    font-family: "YouSheBiaoTiHei";
    font-weight: 400;
    line-height: 48px;
    color: #019969;
    opacity: 1;
  }
  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .input-invite {
      width: 165px;
      height: 32px;
      background: rgba(2, 167, 115, 0.31);
      border: 1px solid #27cc90;
      opacity: 0.88;
      border-radius: 5px;
      border-radius: 5px;
      font-size: 14px;
      font-family: "PingFang SC";
      font-weight: 400;
      line-height: 32px;
      text-align: center;
      color: #27cc90;
    }
    .confirm-btn {
      margin-top: 100px;
      width: 179px;
      height: 41px;
      background: linear-gradient(90deg, #05c68b 0%, #019d6b 100%);
      box-shadow: 0px 3px 6px rgba(1, 153, 105, 0.25);
      opacity: 1;
      border-radius: 21px;
      font-size: 16px;
      font-family: "PingFang SC";
      font-weight: bold;
      line-height: 41px;
      text-align: center;
      border: none;
      color: #ffffff;
    }
  }
}
</style>
